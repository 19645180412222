<template>
    <div class="contactPerson">
        <!--            轮播图-->
        <div class="clenaing_gray" >
            <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" :show-indicators="indicators" :height="height">
                <van-swipe-item v-for="(image, index) in bannerList" :key="index"><img :src="image.bannerPath" class="grayDiv_image" @click="advert(image,index)"></van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>
<script>
    import { Overlay,Button,Swipe,SwipeItem } from 'vant';
    import Vue from 'vue';
    Vue.use(Swipe,SwipeItem);
    export default {
        components: {
            [Overlay  .name]:Overlay,
            [Button  .name]:Button,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
        },
        props: {
            autoplay: {
                type: String,
                default:''
            },
            bannerList: {
                // type: Array,
                // default: () => [],
            },
            indicators:{
                type: Boolean,
                default:true
            },
            height:{
                type: String,
                default:''
            }
        },
        data() {
            return {
            }
        },
        methods:{
            advert(item,index){//触发父类的advert方法 传入参数 item 轮播对象 index 图片下下标
                this.$emit('swiperJump',item,index);
            }
        }
    }
</script>

<style lang="less" scoped >
    @import "../../../assets/less/maskLayer.less";
    .clenaing_gray {
        width: 345px;
        border-radius: 8px;
        background-color: rgba(216, 216, 216, 1);
        box-shadow: 0px 6px 6px -4px rgba(129, 129, 129, 0.4);
    }
    .grayDiv_image{
        width: 100%;
        height: 100%;
        border-radius: 8px;

    }

</style>